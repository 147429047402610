<template>
  <div class="ivy-aside">
    <el-menu
      :default-active="activeItem"
      class="ivy-menu"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>{{ $t("courses.course_management") }}</span>
        </template>
        <el-menu-item index="adminCoursesList">{{
          $t("courses.course_review")
        }}</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "LeftBar",
  mixins: [],
  props: ["activeItem"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {
    handleSelect(key) {
      console.log(key);
      this.$router.replace({
        name: key,
        params: { pathName: "adminStudentProgress" }
      });
    }
  }
};
</script>

<style scoped>
.ivy-aside {
  position: fixed;
  height: 100%;
  width: 210px;
}
.ivy-menu {
  height: 100%;
}
</style>
