<template>
  <div class="backend">
    <left-bar :activeItem="activeItem"></left-bar>
    <div class="backend-body">
      <router-view />
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import LeftBar from "./LeftBar.vue";
export default {
  components: {
    LeftBar
  },
  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {
    activeItem() {
      let activeItem = "";
      if (
        this.$route.name == "adminCoursesList" ||
        this.$route.name == "addCourseStudents" ||
        this.$route.name == "adminEditCourse"
      ) {
        activeItem = "adminCoursesList";
      }

      if (
        this.$route.name == "adminStudentsList" ||
        this.$route.name == "adminStudentProgress"
      ) {
        activeItem = "adminStudentsList";
      }

      return activeItem;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.backend {
  height: calc(100vh - 60px);
}
.backend-body {
  margin-left: 200px;
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
}
</style>
